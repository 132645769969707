<template>
  <v-container fluid>
    <h1>Track & Trace <span class="accent--text">#{{orderId}}</span></h1>
    <h3 class="font-weight-regular grey--text text--darken-2 my-2">
      {{$t('Cargo info')}}
    </h3>
    <template v-for="stage in data.filter(item=>!!item[0].actualEndDateTime)">
      <div :key="stage.id">
        <h3>
          <span class="accent--text">{{stage[0].actualEndDateTime|dateShort}} </span>
          {{stage[0].stageTypeName}}
        </h3>
        <p>
          {{stage.notification}}
        </p>
      </div>
    </template>
  </v-container>
</template>

<script>

export default {
  name: 'PccTrackList',
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      data: [],
    };
  },
  created () {
    if (this.orderId) {
      this.loadData();
    }
  },
  methods: {
    async loadData () {
      try {
        const path    = ['stages', this.orderId];
        const success = await this.API.get.progress(path, this.props);
        this.data     = success.data.reverse();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
